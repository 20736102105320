import React from "react"
import Header from "../header/Header"
import Top from "../header/Top"

const AppHeader = ({ node, first, language }) => (
    <>
        <Header node={node} first={first} language={language} />
        <Top />
    </>
)
export default AppHeader
