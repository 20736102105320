import React from "react"
import { Region } from "lib"
import PageLayout from "./PageLayout"
import GalleryLayout from "../gallery/Layout"
import Home from "../home/Home"
import Top from "../top/Top"
import BusinessLayout from "../business/BusinessLayout"
import TrophyLayout from "../trophy/TrophyLayout"

export const DefaultLayout = ({ entity, language }) => (
    <Region entity={entity} language={language} dynamic />
)

export const nodeLayout = {
    homepage: Home,
    top: Top,
    trophy: TrophyLayout,
    page: PageLayout,
    gallery: GalleryLayout,
    businessNode: BusinessLayout,
    NotFound: PageLayout,
}
