import { useMemo } from "react"
import customNodes from "conf/node"

export const isCustomNode = pathname => {
    if (customNodes[pathname])
        //return wrapInfo({ ...customNodes[pathname], type: "node", path: pathname })
        return { ...customNodes[pathname], type: "node", path: pathname }
    return false
}
const useCustomNode = pathname => useMemo(() => isCustomNode(pathname), [pathname])
export default useCustomNode
