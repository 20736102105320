import { ReactComponent as ArrowLeft } from "./svg/carrousel-arrow-left-1.svg"
import { ReactComponent as ArrowRight } from "./svg/carrousel-arrow-right-1.svg"
import { ReactComponent as Menu } from "./svg/menu.svg"
import { ReactComponent as SubArrowRight } from "./svg/outline-subdirectory_arrow_right-24px.svg"
import { ReactComponent as Settings } from "./svg/settings.svg"
import { ReactComponent as Industry } from "./svg/simboluri-domenii/sb-01-industrie-b.svg"
import { ReactComponent as Research } from "./svg/simboluri-domenii/sb-02-cercetare-b.svg"
import { ReactComponent as Agriculture } from "./svg/simboluri-domenii/sb-03-agrigultura-c.svg"
import { ReactComponent as Constructions } from "./svg/simboluri-domenii/sb-04-constructii-c.svg"
import { ReactComponent as Services } from "./svg/simboluri-domenii/sb-05-servicii.svg"
import { ReactComponent as Commerce } from "./svg/simboluri-domenii/sb-06-comert.svg"
import { ReactComponent as Tourism } from "./svg/simboluri-domenii/sb-07-turism.svg"

import { ReactComponent as Trofeu } from "./svg/trofeu-ccibn-simple.svg"
import { ReactComponent as Map } from "./svg/sb-harta-b.svg"
import { ReactComponent as Skyline } from "./svg/Bistrita-skyline-gallery.svg"
//import { ReactComponent as SkylineP } from "./svg/Bistrita-skyline-gall-p.svg"

const icons = {
    Trofeu,
    Map,
    Skyline, //P, SkylineL,

    Industry,
    Research,
    Agriculture,
    Constructions,
    Services,
    Commerce,
    Tourism,

    ArrowLeft,
    ArrowRight,
    Menu,
    SubArrowRight,

    Settings,
}
export default icons
