import React from "react"
import { Modal, FaIcon, Icon, useScreen, useSubscription, publish } from "lib"
import SearchBarYearSelect from "./SearchBarYearSelect"
import useSearchReducer from "./useSearchReducer"
import { WithTooltip } from "../lib"
import Map from "./map/Map"

const MapTooltip = () => (
    <>
        <h2>Deschide HARTA!</h2>
        <div>Vezi Topul Firmelor pe harta județului Bistrița-Năsăud.</div>
    </>
)
const SearchBar = () => {
    const height = React.useRef(0)
    const screen = useScreen()
    const [state, dispatch] = useSearchReducer()
    const [map, setMap] = React.useState(false)
    const [requestClose, setRequestClose] = React.useState(false)
    const { year, search, filters } = state
    useSubscription("website-loaded", () => {
        dispatch({ type: "noop" })
    })
    //console.log(filters)
    React.useLayoutEffect(() => {
        const h = document.querySelector(".search-bar").getBoundingClientRect().height
        if (height.current === h) return
        height.current = h
        document.documentElement.style.setProperty("--search-bar-height", `${h}px`)
    })
    useSubscription("add-filter", (type, filter) => {
        dispatch({ type, filter })
    })
    const handleChange = e => {
        dispatch({ type: "search", value: e.currentTarget.value })
        window.scrollTo(0, 0)
    }
    const handleFocus = e => {
        dispatch({ type: "search-focus" })
        publish("title", "Topul Județean al Firmelor Bistrița-Năsăud " + year)
    }
    const handleBlur = e => dispatch({ type: "search-blur" })
    const openMap = e => {
        setMap(map => !map)
    }
    const handleMapClose = () => {
        setMap(false)
        setRequestClose(false)
    }
    const handleRequestClose = () => {
        setRequestClose(true)
    }
    const handleChipClose = e => {
        const label = e.currentTarget.dataset.label
        dispatch({ type: "remove-filter", label })
    }
    return (
        <div className="search-bar">
            <WithTooltip
                tooltip={MapTooltip}
                tooltipStyle="info"
                className="map-icon"
                onClick={openMap}
            >
                <Icon name="Map" />
            </WithTooltip>
            <div className="search-bar-inner">
                <SearchBarYearSelect year={year} />
                <input
                    type="text"
                    value={search}
                    placeholder="Căutați o firmă, un domeniu..."
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>
            {filters && (
                <div className="keywords">
                    {filters.map((filter, i) => (
                        <div key={i} className="chip">
                            <div className="label">{filter.label}</div>
                            <div
                                className="chip-close"
                                data-label={filter.label}
                                onClick={handleChipClose}
                            >
                                <FaIcon icon="faTimesCircle" />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {map && (
                <Modal
                    name="map"
                    type="full"
                    onClose={handleMapClose}
                    w={screen.W}
                    h={screen.H}
                    requestClose={requestClose}
                >
                    <Map w={screen.W} h={screen.H} requestClose={handleRequestClose} />
                </Modal>
            )}
        </div>
    )
}
export default SearchBar
