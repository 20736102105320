import React from "react"
import { Icon, publish } from "lib"
import { domains } from "../lib"

const Domains = () => {
    const handleClick = e => {
        const domain = e.currentTarget.dataset.domain
        publish("add-filter", [{ label: domain, filter: { domain } }])
    }
    return (
        <div id="domains">
            <div className="domains-inner">
                {Object.keys(domains).map((d, i) => (
                    <div key={i} className="domain" data-domain={d} onClick={handleClick}>
                        <Icon name={domains[d]} id={`domain-${domains[d]}`} />
                        <div className="label">{d}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Domains
