import React from "react"
import { useQuery, publish } from "lib"

const queryConfig = {}
const query = {
    distinct: "business",
    key: "year",
}
const usePeriod = year => {
    const [period] = useQuery(query, queryConfig)
    React.useMemo(() => {
        if (period && period.length > 0 && period.indexOf(year) < 0)
            publish("year", period.sort()[period.length - 1])
    }, [period, year])
    return (period || []).sort()
}
const SearchBarYearSelect = ({ year }) => {
    const [open, setOpen] = React.useState(false)
    const period = usePeriod(year)
    const handleSelectYear = e => {
        const year = parseInt(e.currentTarget.dataset.year, 10)
        publish("year", year)
        publish("title", "Topul Județean al Firmelor Bistrița-Năsăud " + year)
        setOpen(false)
    }
    const handleMouseEnter = e => {
        setOpen(true)
    }
    const handleMouseLeave = e => {
        setOpen(false)
    }
    const handleClick = e => {
        setOpen(state => !state)
    }

    return (
        <div
            className={`year-select${open ? " open" : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="year" onClick={handleClick}>
                {year}
            </div>
            <div className="year-options">
                {period.map((y, i) => (
                    <div key={i} data-year={y} onClick={handleSelectYear}>
                        {y}
                    </div>
                ))}
            </div>
        </div>
    )
}
export default SearchBarYearSelect
