import React, { useRef, useState, useLayoutEffect, useCallback } from "react"
import { History, useScreen } from "lib"
import NodeManager from "./node/NodeManager"

const Location = () => {
    const [location, setLocation] = useState(History.getLocation())

    const handleLocationChange = useCallback((pathname, search, language) => {
        setLocation({ pathname, search, language })
    }, [])

    const screenClasses = useRef()
    useScreen((w, h, mq, o) => {
        if (typeof window === "undefined") return
        //console.log(w, h, mq, o)
        //const res = document.querySelector("#resolution")
        //if (res) res.innerHTML = `${w}x${h}@${window.devicePixelRatio}dppx`
        const newScreenClasses = `${mq} ${o} ${w < 400 || h < 400 ? "mobile" : ""}`
        if (screenClasses.current === newScreenClasses) return
        screenClasses.current = newScreenClasses
        document.body.className = screenClasses.current
    })

    useLayoutEffect(() => {
        History.addLocationListener(handleLocationChange)
        return () => History.removeLocationListener(handleLocationChange)
    }, [handleLocationChange])

    //console.log(location.search)
    return (
        <NodeManager
            pathname={location.pathname}
            search={location.search}
            language={location.language}
        />
    )
}
export default Location
