import React from "react"
import { useSubscription, publish } from "lib"

export const searchInitialState = {
    filters: [],
    search: "",
    searchFocus: false,
    year: new Date().getFullYear(),
}
const reducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
        case "year":
            return { ...state, year: action.year }
        case "noop":
            return { ...state }
        case "search":
            return { ...state, search: action.value }
        case "add-filter":
            const newFilters = action.filter.filter(f => {
                let add = true
                state.filters.forEach(f1 => {
                    if (f1.label === f.label) add = false
                })
                return add
            })
            return { ...state, filters: [...state.filters, ...newFilters] }
        case "remove-filter":
            return { ...state, filters: state.filters.filter(f => f.label !== action.label) }
        case "search-focus":
            return { ...state, searchFocus: true }
        case "search-blur":
            return { ...state, searchFocus: false }
        default:
            return state
    }
}
const useSearchReducer = () => {
    const r = React.useReducer(reducer, searchInitialState)
    const [, dispatch] = r
    useSubscription("year", (topic, year) => dispatch({ type: "year", year }))
    React.useMemo(() => {
        publish("search", r)
    }, [r])
    return r
}

export default useSearchReducer
