import React from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import { useSubscription } from "lib"
/*
const defaultBg = {
    id: "bg-image",
    style: {
        backgroundImage: "url(/img/layout/1-lg-l-c.jpg)",
        backgroundPosition: "center bottom",
        backgroundRepeat: 'no-repeat',
        backgroundSize: "contain"
    }
}*/

const Bg = () => {
    const [bg, setBg] = React.useState([])
    useSubscription("push-bg,pop-bg", (topic, value) => {
        if (topic === "push-bg") setBg(bg => [...bg, value])
        if (topic === "pop-bg") setBg(bg => bg.filter(b => b.id !== value.id))
    })
    const index = bg.length - 1

    return (
        <div id="bg">
            <div id="bg-image" />
            <TransitionGroup id="bg-other" appear={true} enter={true}>
                <Transition
                    key={index}
                    in={index >= 0}
                    appear={true}
                    unmountOnExit={true}
                    timeout={1000}
                >
                    {status => index >= 0 && <div className={`bg bg-${status}`} {...bg[index]} />}
                </Transition>
            </TransitionGroup>
        </div>
    )
}

export default React.memo(Bg)
