import dataAfterSave from "./dataImport"

/*
const emptyHTML = value =>
    !value ||
    !value.raw ||
    !value.raw.blocks ||
    value.raw.blocks.reduce((acc, block) => `${acc}${block.text.trim()}`, "") === ""*/
/*const checkImage = value =>
    !value ||
    value.length === 0 ||
    value
        .map(img => img.mime && img.mime.substring(0, 5) === "image")
        .reduce((acc, isImg) => acc && isImg, true)
*/
const nodeBundles = {
    page: {
        name: "Pagină",
        info: "Pagină generică",
        searchField: "title",
        //dynamicFields: ["html", "quote", "block", "icon", "img", "video"],
        fields: {
            title: { fullWidth: true },
        },
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            //gallery: [value => checkImage(value), "În galerie se pot adăuga doar imagini."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
    },
    NotFound: {
        name: "Pagină inexistentă",
        _noCreate: true,
    },
    homepage: {
        name: "Home",
        _noCreate: true,
    },
    businessNode: {
        name: "Firma",
        searchField: "title",
        //_noCreate: true,
    },
    gallery: {
        name: "Galerie",
        searchField: "title",
        //_noCreate: true,
    },
    trophy: {
        name: "Trofeu",
        searchField: "title",
        //_noCreate: true,
        getLabel: e => `${e.kind ? "Trofeul Exportatorilor" : "Trofeul Creativității"} ${e.year}`,
    },
}

const entityTypes = {
    data: {
        name: "Import date",
        collection: "site",
        afterSave: dataAfterSave,
        getLabel: data => (data.year ? data.year : "Date"),
    },
    ad: {
        name: "Reclama",
        collection: "ad",
        getLabel: ad => (ad.business ? ad.business.title : "ad"),
    },
    sponsors: {
        name: "Sponsori",
        collection: "sponsors",
        getLabel: sponsors => sponsors.year || "Fără an",
    },
    bg: {
        name: "Fundal",
        collection: "site",
    },
    business: {
        name: "Detalii Firma",
        collection: "business",
        getLabel: b => b.name,
        searchField: "name",
        //_noCreate: true,
    },
    footer: {
        name: "Footer",
        collection: "site",
    },
    menu: {
        name: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        //_noCreate: true,
    },
}

export { nodeBundles, entityTypes }
