import React from "react"
import { useQuery, publish, Link, LazyImage } from "lib"

const bannersQuery = {
    collection: "ad",
    query: {},
}
const options = {}
const shuffle = a => {
    let array = [...a]
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)) // random index from 0 to i
        // swap elements array[i] and array[j]
        // we use "destructuring assignment" syntax to achieve that
        // you'll find more details about that syntax in later chapters
        // same can be written as:
        let t = array[i]
        array[i] = array[j]
        array[j] = t
        //    (array[i], array[j])
        //] = [array[j], array[i]])
    }
    return array
}
const useBanner = n => {
    const [results] = useQuery(bannersQuery, options)
    const banners = React.useMemo(() => {
        if (!results || results.length === 0) return []

        publish("no-banners", results.length)
        return shuffle(results)
    }, [results])

    if (n >= banners.length) return null
    return banners[n]
}

const Banner = ({ n }) => {
    const banner = useBanner(n)
    if (!banner) return null

    return (
        <div className="banner">
            {banner.business ? (
                <Link to={banner.business.path}>
                    <LazyImage src={banner.file[0]} alt="" proportional />
                </Link>
            ) : (
                <LazyImage src={banner.file[0]} alt="" />
            )}
        </div>
    )
}

export default Banner
