import React from "react"
import Business from "./Business"
import { renderString } from "../lib"
import { useSubscription, Icon } from "lib"

const Group = ({ fullTop, group, search }) => {
    const ref = React.useRef()
    const refContent = React.useRef()
    useSubscription("website-loaded")
    /*
    const [, setState] = React.useState(0)
    useSubscription("website-loaded", () => {
        setState(1)
    })*/
    React.useLayoutEffect(() => {
        if (!fullTop) return
        const grid = document.querySelector(".domain-content")
        const style = window.getComputedStyle(grid)
        const rowGap = parseInt(style.getPropertyValue("grid-row-gap"))
        const rowHeight = parseInt(style.getPropertyValue("grid-auto-rows"))
        //const rowGap = 20
        //const rowHeight = 0
        const height = refContent.current.getBoundingClientRect().height
        const rowSpan = Math.ceil((height + rowGap) / (rowHeight + rowGap))
        //console.log(rowGap, rowHeight, height, rowSpan)
        ref.current.style.gridRowEnd = "span " + rowSpan
        //ref.current.style.opacity = 1
    })

    if (!fullTop) return null
    //console.log(group)
    //const domain = group.domain
    //const domains = fullTop.filter(d => d.domain === group.domain)
    //if (domains.length === 0) return null
    //const currentDomain = domains[0]
    //const groups = currentDomain.groups.filter(g => g.group === group.group)
    /*
    const groups = fullTop.filter(g => g.group === group.group)
    if (groups.length === 0) return null
    const currentGroup = groups[0]
    */

    const currentGroup = group
    return (
        <div className="group" ref={ref}>
            <div ref={refContent}>
                <h3 className="group-name">
                    <span className="caen">
                        <Icon name="Trofeu" className="trofeu" />
                        <span>{group.groupId}</span>
                    </span>
                    <span className="caenstr">{renderString(group.group, search)}</span>
                </h3>
                <div className="group-content">
                    {currentGroup.sizes.map((size, k) => (
                        <div key={k} className="group-size">
                            <h5>{size.size}</h5>
                            <div className="group-size-content">
                                <div className="group-size-content-inner">
                                    {size.top.map((business, l) => (
                                        <Business key={l} business={business} search={search} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Group
