const customNodes = {
    "/": {
        _id: { $oid: "home" },
        bundle: "homepage",
        title: "",
    },
    "/index.html": {
        _id: { $oid: "home" },
        bundle: "homepage",
        title: "",
    },
    "/top-firme": {
        _id: { $oid: "top-firme" },
        bundle: "top",
        title: "Topul județean al firmelor, Bistrița-Năsăud",
    },
}
export default customNodes
