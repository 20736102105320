import axios from "axios"
import { publish, realPath, Entity, Query } from "lib"
import parse from "csv-parse"

export const prepareString = s =>
    s
        .trim()
        .split("-")
        .join(" ")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/[\s+]/gim, " ")
        .replace(/[^0-9a-z\s]/gim, "")

const splitWords = str => str.split(/[ -./\\()"',;<>~!@#$%^&*|+=[\]{}`~?:]/u)
const ngramWord = str => {
    let ngr = []
    if (str.length < 2) return []
    if (str.length === 2) return [str]
    //return [str]
    for (let i = 3; i <= str.length; i++) ngr.push(str.substring(0, i))
    return ngr.concat(ngramWord(str.substring(1)))
}
const ngram = (a, split = true) => {
    let ngr = []
    for (let i in a) {
        /*if (split) {
            const words = splitWords(prepareString(a[i]))
            for (let j in words) ngr = ngr.concat(ngramWord(words[j]))
        } else {*/
        ngr = ngr.concat(ngramWord(prepareString(a[i])))
        //}
    }
    return Array.from(new Set(ngr))
}
/*
const insertTop = (year, top) => {
    const collection = "top" + year
    const domains = Object.keys(top)
    const insertOne = (domains, top) => {
        if (domains.length === 0) return
        const domain = domains.shift()
        const q = {
            collection,
            data: {
                domain,
                top: top[domain],
            },
        }
        insert(q).then(() => {
            insertOne(domains, top)
        })
    }
    return axios
        .delete(`${API}/data/${collection}`)
        .then(response => {
            console.log(response)
            insertOne(domains, top)
            return
        })
        .catch(error => console.log(error))
}
*/
const findBusinessesByCUI = cui =>
    new Promise((resolve, reject) => {
        const q = {
            collection: "business",
            query: { cui },
        }
        try {
            return Query.query(q, data =>
                resolve(data.results && data.results.length > 0 ? data.results : [])
            )
        } catch (e) {
            reject(e)
            return []
        }
    })

const findBusinessNode = cui =>
    new Promise((resolve, reject) => {
        const q = {
            collection: "node",
            query: { bundle: "businessNode", cui },
        }
        try {
            return Query.query(q, data =>
                resolve(data.results && data.results.length > 0 ? data.results[0] : null)
            )
        } catch (e) {
            reject(e)
        }
    })

let businessFields = {
    cui: 0,
    regCom: 1,
    name: 2,
    inc: 3,
    domain: 4,
    groupId: 5,
    group: 6,
    size: 7,
    loc: 9,
    str: 10,
    no: 11,
    bloc: 12,
    sc: 13,
    et: 14,
    ap: 15,
    sector: 16,
    postal: 17,
    siruta: 18,
    phone: 19,
    fax: 20,
    email: 21,
    web: 22,
    judet: 23,
    caen: 24,
    caenstr: 25,
    ca: 26,
    nrsal: 27,
}
let businessIntFields = {
    position: 8,
}
const fieldNames = {
    "Numar de inregistrare": "regCom",
    Nume: "name",
    "Forma juridica": "inc",
    Domeniu: "domain",
    "Cod grupa": "groupId",
    "Denumire grupa": "group",
    "Clasa de marime": "size",
    Pozitie: "position",
    Localitate: "loc",
    Strada: "str",
    Numar: "no",
    Bloc: "bloc",
    Scara: "sc",
    Etaj: "et",
    Apartament: "ap",
    Sector: "sector",
    "Cod postal": "postal",
    Siruta: "siruta",
    Telefon: "phone",
    Fax: "fax",
    Email: "email",
    Web: "web",
    Judet: "judet",
    CAEN: "caen",
    "Descriere CAEN": "caenstr",
    CA: "ca",
    NRSAL: "nrsal",
}
const intFieldNames = ["position"]
const buildFields = record => {
    businessFields = {
        cui: 0,
    }
    businessIntFields = {}
    record.forEach((fieldName, i) => {
        if (fieldNames[fieldName]) {
            //console.log(fieldName, fieldNames[fieldName], intFieldNames)
            if (intFieldNames.indexOf(fieldNames[fieldName]) >= 0)
                businessIntFields[fieldNames[fieldName]] = i
            else businessFields[fieldNames[fieldName]] = i
        }
    })
    console.log(businessFields, businessIntFields)
}

const insertOrUpdateBusinessNode = (year, record, top) => {
    //console.log(year, record, top)
    const cui = record[0]
    const title = record[businessFields["name"]]
    console.log(cui, title)
    return findBusinessNode(cui).then(businessNode =>
        findBusinessesByCUI(cui).then(businesses => {
            console.log(businessNode, businesses)
            let node = businessNode
            if (!node) {
                node = Entity.create("businessNode")
                node = Entity.set(node, "cui", cui)
            }
            const maxYear =
                businesses.length === 0 ? year : Math.max(...businesses.map(b => b.year))
            if (maxYear === year || !node.title) node = Entity.set(node, "title", title)
            else {
                if (node._id) {
                    const b = businesses.filter(b => b.year === year)
                    let business = b.length > 0 ? b[0] : null
                    console.log(b, business)
                    if (!business) {
                        business = Entity.create("business")
                        console.log(business)
                        business = Entity.set(business, "year", year)
                        console.log(business)
                    }
                    business = Entity.set(business, "path", node.path)
                    console.log(business)
                    return insertOrUpdate(business, year, record, top)
                }
            }
            console.log(node)
            return Entity.save(node, null, false, true).then(node => {
                let business = null
                businesses.forEach(b => {
                    b = Entity.set(b, "path", node.path)
                    if (b.year !== year) Entity.save(b)
                    else business = b
                })
                if (!business) {
                    business = Entity.create("business")
                    business = Entity.set(business, "year", year)
                }
                business = Entity.set(business, "path", node.path)
                console.log(business)
                return insertOrUpdate(business, year, record, top)
            })
        })
    )
}

//const searchFields = ["cui", "name", "caenstr", "domain", "group", "judet", "loc"]
const insertOrUpdate = (business, year, record, top) => {
    return new Promise((resolve, reject) => {
        let b = business
        if (!b) {
            b = Entity.create("business")
            b = Entity.set(b, "year", year)
        }
        //console.log(businessFields, businessIntFields)
        Object.keys(businessFields).forEach(field => {
            b = Entity.set(b, field, record[businessFields[field]])
        })
        Object.keys(businessIntFields).forEach(field => {
            b = Entity.set(b, field, parseInt(record[businessIntFields[field]], 10))
            //console.log(b)
        })
        let a = []
        if (record[businessFields["cui"]]) a.push(record[businessFields["cui"]])
        if (record[businessFields["name"]])
            a.push(record[businessFields["name"]].split("-").join(" "))
        const group = record[businessFields["caenstr"]]
            ? record[businessFields["caenstr"]]
            : record[businessFields["group"]]
        if (group) a.concat(splitWords(group))
        if (record[businessFields["domain"]]) a.push(record[businessFields["domain"]])
        if (record[businessFields["loc"]]) a.push(record[businessFields["loc"]])
        b = Entity.set(b, "ngram", ngram(a))
        return Entity.save(b).then(entity => resolve(entity))
    })
}

const updateRecord = (record, year, top) => {
    return new Promise((resolve, reject) => {
        //console.log(record[0].substring(0, 3))
        if (record[0].substring(0, 3) === "CUI") {
            buildFields(record)
            return resolve()
        }
        //console.log(year, record)
        //const cui = record[0]
        return insertOrUpdateBusinessNode(year, record, top)
            .then(() => {
                //console.log("promise updateRecord resolve")
                resolve()
            })
            .catch(e => console.log(e))
        /*
        return findBusinessByCUI(cui, year)
            .then(business => insertOrUpdate(business, year, record, top))
            .then(() => {
                //console.log("promise updateRecord resolve")
                resolve()
            })
            .catch(e => console.log(e))
            */
    })
}

const prepare = (parser, year, top, total, done) => {
    return new Promise((resolve, reject) => {
        let d = done
        let key = new Date().getTime()
        const doPrepare = () => {
            const record = parser.read()
            d += 1
            if (d % 10 === 0) {
                //if (key !== 0) publish("hide", key)

                //key = new Date().getTime()
                publish("info", {
                    key,
                    message:
                        "Nu închideți pagina, se proceseaza... (" +
                        Math.ceil((d * 100) / total) +
                        "%)",
                    nohide: true,
                })
            }
            if (!record) {
                if (key !== 0) publish("hide", key)
                resolve(d)
                return false
            }
            console.log(record, year, top)
            updateRecord(record, year, top).then(() => {
                doPrepare()
            })
        }
        doPrepare()
    })
}
//const insertOrUpdate = ()
const dataAfterSave = entity => {
    if (!entity.file || entity.file.length === 0) return

    //console.log(entity)
    const year = entity.year
    const csvFile = realPath(entity.file[0].url)
    const parser = parse({})
    let working = false
    let total = 0
    let done = 0
    let top = {}
    parser.on("readable", () => {
        if (working) return
        working = true
        return prepare(parser, year, top, total, done).then(d => {
            done = d
            working = false
            //insertTop(entity.year, top)
        })
    })
    /*
        let record
        while ((record = parser.read())) {
            prepare(record)
        }
    })*/
    // Catch any error
    parser.on("error", function(err) {
        console.error(err.message)
    })
    // When we are done, test that the parsed output matched what expected
    parser.on("end", function() {
        //console.log("end")
        publish("success", "Datele au fost procesate cu succes.")
    })
    axios.get(csvFile).then(response => {
        total = response.data.split("\n").length
        parser.write(response.data)
        parser.end()
        /*
            const lines = response.data.split("\n")
            stream.on("data", chunk => {
            console.log(chunk)
            parser.write(chunk)*/
    })
    //stream.on("end", () => parser.end())
    //})*/
}

export default dataAfterSave
