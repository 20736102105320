import React from "react"
import { useQuery, Link, LazyImage } from "lib"

const options = {}
const query = {
    collection: "sponsors",
    query: {},
    sort: { year: -1 },
    limit: 1,
}
const useData = () => {
    const [data] = useQuery(query, options)
    return data ? data[0] : null
}

const Sponsor = ({ sponsor }) => {
    if (
        !sponsor ||
        !sponsor.business ||
        !sponsor.business.logo ||
        sponsor.business.logo.length === 0
    )
        return null
    return (
        <div className="sponsor">
            <Link to={sponsor.business.path}>
                <LazyImage src={sponsor.business.logo[0]} alt="" noth />
            </Link>
        </div>
    )
}

const Sponsors = () => {
    const sponsors = useData()
    if (!sponsors) return null
    //console.log(sponsors)
    return (
        <div className="sponsors">
            <div className="sponsors-header">
                <div className="line1">Topul Firmelor 2019, Bistrița-Năsăud</div>
                <div className="line1">Sponsori</div>
            </div>
            <div className="main-sponsors">
                {sponsors.main.map((s, i) => (
                    <Sponsor key={i} sponsor={s} />
                ))}
            </div>
            <div className="regular-sponsors">
                {sponsors.regular.map((s, i) => (
                    <Sponsor key={i} sponsor={s} />
                ))}
            </div>
        </div>
    )
}
export default Sponsors
