import React from "react"
import { Link } from "lib"
import { WithTooltip } from "../lib"
import { prepareString } from "conf/dataImport"

export const renderAddress = business => {
    let line1 = []
    if (business.loc) line1.push(business.loc)
    if (business.str) line1.push(business.str)
    if (business.no) line1.push("Nr. " + business.no)
    if (business.bloc) line1.push("Bl. " + business.bloc)
    if (business.sc) line1.push("Sc. " + business.sc)
    if (business.et) line1.push("Et. " + business.et)
    if (business.ap) line1.push("Ap. " + business.ap)
    let line2 = []
    if (business.judet) {
        if (business.postal) line2.push(business.postal + " " + business.judet)
        else line2.push(business.judet)
    }
    return (
        <>
            {line1.join(", ")}
            <br />
            {line2.join(", ")}
        </>
    )
}
const prepareString2 = s =>
    s
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()

export const renderString = (str, search) => {
    if (!search || search.trim() === "") return str
    const s = prepareString2(search)
    const sp = prepareString(search)
    const s1 = prepareString2(str)
    let i = s1.indexOf(s)
    if (i < 0) {
        i = s1.indexOf(sp)
        if (i < 0) return str
        return (
            <>
                {str.substring(0, i)}
                <span className="highlight">{str.substring(i, i + sp.length)}</span>
                {str.substring(i + sp.length)}
            </>
        )
    }
    return (
        <>
            {str.substring(0, i)}
            <span className="highlight">{str.substring(i, i + search.length)}</span>
            {str.substring(i + search.length)}
        </>
    )
}

export const BusinessCard = ({ business, search }) => {
    //console.log(search, business)
    let className = "name"
    if (
        search &&
        (business.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            business.cui.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            business.loc.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            business.judet.toLowerCase().indexOf(search.toLowerCase()) >= 0)
    )
        className = "name highlighted"
    return (
        <div className="business">
            <div className="position">{business.position}</div>
            <div className="business-content">
                <div className={className}>{business.name}</div>
                <div className="cui">CUI: {business.cui}</div>
                <div className="address">{renderAddress(business)}</div>
                {business.web && (
                    <div className="web">
                        <a
                            href={`http://${business.web}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {business.web}
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}
const Business = ({ business, search }) => {
    const showTooltip = () => <BusinessCard business={business} search={search} />
    return (
        <WithTooltip className="business-row" tooltipStyle="business-card" tooltip={showTooltip}>
            <div className="business-position">{business.position}</div>
            <div className="business-name">
                <Link to={business.path}>{renderString(business.name, search)}</Link>
            </div>
        </WithTooltip>
    )
}
export default Business
