import React from "react"
import { useQuery, publish } from "lib"

const queryConfig = {}
const useTop = year => {
    const stateRef = React.useRef()
    const yearRef = React.useRef()
    const query = React.useMemo(() => {
        if (!year) return null
        return {
            collection: "business",
            pipeline: [
                { $match: { year, position: { $lte: 3 } } },
                { $sort: { position: 1 } },
                //{ $sort: { score: { $meta: "textScore" } } },
                {
                    $project: {
                        domain: 1,
                        groupId: 1,
                        group: 1,
                        size: 1,
                        name: 1,
                        cui: 1,
                        str: 1,
                        no: 1,
                        bloc: 1,
                        sc: 1,
                        et: 1,
                        ap: 1,
                        sector: 1,
                        postal: 1,
                        loc: 1,
                        judet: 1,
                        siruta: 1,
                        position: 1,
                        web: 1,
                        path: 1,
                        _id: 1,
                    },
                },
                {
                    $group: {
                        _id: { domain: "$domain", groupId: "$groupId", size: "$size" },
                        domain: { $first: "$domain" },
                        group: { $first: "$group" },
                        groupId: { $first: "$groupId" },
                        size: { $first: "$size" },
                        top: { $push: "$$ROOT" },
                    },
                },
                { $sort: { size: 1 } },
                {
                    $group: {
                        _id: { domain: "$_id.domain", groupId: "$_id.groupId" },
                        domain: { $first: "$domain" },
                        group: { $first: "$group" },
                        groupId: { $first: "$groupId" },
                        sizes: { $push: { size: "$size", top: "$top" } },
                    },
                },
                { $sort: { domain: 1, group: 1 } },
                {
                    $group: {
                        _id: { domain: "$_id.domain" },
                        domain: { $first: "$domain" },
                        groups: {
                            $push: { group: "$group", groupId: "$groupId", sizes: "$sizes" },
                        },
                    },
                },
                { $sort: { domain: 1 } },
            ],
        }
    }, [year])

    const [top, state] = useQuery(query, queryConfig)

    if (state !== stateRef.current || year !== yearRef.current) {
        stateRef.current = state
        yearRef.current = year
        if (state === "loaded") {
            //console.log(top)
            let loc = {}
            //let locs = {}
            top.forEach(d => {
                d.groups.forEach(g => {
                    g.sizes.forEach(s => {
                        s.top.forEach(b => {
                            //const name1 = b.loc.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            //if (locs[name1]) locs[name1] = locs[name1] + 1
                            //else locs[name1] = 1
                            const name = parseInt(b.siruta, 10)
                            if (loc[name]) loc[name] = loc[name] + 1
                            else loc[name] = 1
                        })
                    })
                })
            })
            //console.log(locs)
            publish("toploc", loc)
            publish("top", top)
        }
    }
    //console.log(year, query, state, total, top)

    //console.log(top)
    return top
}
export default useTop
