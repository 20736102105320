import React from "react"
import { Link, realPath, Region } from "lib"

const Home = ({ entity, language }) => (
    <Region entity={entity} language={language}>
        <div className="top-title">
            <h2>Topul Firmelor 2019, editia XXVI</h2>
            <h3>24 octombrie 2019, ora 17:30</h3>

            <h3>Hotel Metropolis, Bistrita</h3>
        </div>
        <div className="top-buttons">
            <div>
                <Link to="/trofeul-creativitatii" className="top-button creativity-top-button">
                    <div className="circle">
                        <div className="circle-in">
                            <img
                                src={realPath(
                                    "/img/layout/3-trofee-ccibn/trofeu-ccibn-creativitatii.svg"
                                )}
                                alt="Trofeul Creativității"
                            />
                        </div>
                    </div>
                    <img
                        src={realPath(
                            "/img/layout/simboluri-topuri-intro/01-trofeul-creativitatii-1.svg"
                        )}
                        alt="Trofeul Creativității"
                    />
                </Link>
            </div>
            <div>
                <Link className="top-button business-top-button" to="/top-firme">
                    <div className="circle">
                        <div className="circle-in">
                            <img
                                src={realPath("/img/layout/trofeu-ccibn-1.svg")}
                                alt="Topul Firmelor"
                            />
                        </div>
                    </div>
                    <img
                        src={realPath("/img/layout/simboluri-topuri-intro/02-topul-firmelor-1.svg")}
                        alt="Topul Firmelor"
                    />
                </Link>
            </div>
            <div>
                <Link to="/trofeul-exportatorilor" className="top-button export-top-button">
                    <div className="circle">
                        <div className="circle-in">
                            <img
                                src={realPath(
                                    "/img/layout/3-trofee-ccibn/trofeu-ccibn-exportatorilor.svg"
                                )}
                                alt="Trofeul Exportatorilor"
                            />
                        </div>
                    </div>
                    <img
                        src={realPath(
                            "/img/layout/simboluri-topuri-intro/03-trofeul-exportatorilor-1.svg"
                        )}
                        alt="Trofeul Exportatorilor"
                    />
                </Link>
            </div>
        </div>
    </Region>
)

export default Home
