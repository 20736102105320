import React from "react"
import Bg from "./Bg"
import { useSubscription, publish } from "lib"
import useTop from "./useTop"

const Top = () => {
    const [year] = useSubscription("year")
    if (!year) publish("year", new Date().getFullYear())
    useTop(year)
    return (
        <>
            <Bg />
        </>
    )
}
export default React.memo(Top)
