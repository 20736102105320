import React from "react"
import useMapData from './useMapData'
import {useSubscription} from "lib"
import * as d3 from 'd3'
import { geoMercator, geoPath } from "d3-geo"

const useMap = (w, h) => {
    const svg = React.useRef()
    const projection = React.useRef()
    const ref = React.useRef()
    const [uats, locs] = useMapData()
    const [toploc] = useSubscription("toploc")
    const [topuat, max, ] = React.useMemo(() => {
        if (!locs || !toploc) return [null, 1]
        const topuat = {}
        const locuat = {}
        const uatlocs = {}
        locs.features.forEach(loc => {
            locuat[loc.properties.natCode] = loc.properties.supCode
            if (uatlocs[loc.properties.supCode])
                uatlocs[loc.properties.supCode].push(
                    [loc.properties.natCode.toString(),loc.properties.name])
            else uatlocs[loc.properties.supCode] = [
                [loc.properties.natCode.toString(),loc.properties.name]]
        })
        Object.keys(toploc).forEach(loc => {
            const uat = locuat[loc]
            if (topuat[uat]) topuat[uat] += toploc[loc]
            else topuat[uat] = toploc[loc]
        })
        let max = 1

        Object.keys(topuat).forEach(key => {
            if (max < topuat[key]) max = topuat[key]
        })

        return [topuat, max, uatlocs]
    }, [locs, toploc])

    React.useEffect(() => {
        if (!uats || !locs || !topuat || !toploc) return

        if(svg.current) {
            svg.current
                .attr("viewBox", [0, 0, w, h])
                .attr("width", w)
                .attr("height",  h)
            projection.current.fitSize([w, h], uats)
            const render = geoPath().projection(projection.current)
            svg.current.selectAll("path.uat")
                .attr("d", render)
            const radiusScale = d3.scaleLog()
                  .domain([1, max+1])
                  .range([1, Math.min(w, h)/40])
            const renderLoc =geoPath().pointRadius(
                f => radiusScale((toploc[parseInt(f.properties.natCode, 10)] || 0)+1)
            ).projection(projection.current)
            svg.current.selectAll("path.loc")
                .attr("d", renderLoc)

        } else {
            svg.current = d3.select(ref.current)
                .append('svg')
                .attr("viewBox", [0, 0, w, h])


            // filters go in defs element
            const defs = svg.current.append("defs");

            // create filter with id #drop-shadow
            // height=130% so that the shadow is not clipped
            const filter = defs.append("filter")
                  .attr("id", "glow")
                  .attr("x", "-150%")
                  .attr("y", "-150%")
                  .attr("height", "400%")
                  .attr("width", "400%");
            /*        var feMerge = filter.append("feMerge");

                      feMerge.append("feMergeNode")
                      .attr("in", "coloredBlur")
                      feMerge.append("feMergeNode")
                      .attr("in", "coloredBlur")
                      feMerge.append("feMergeNode")
                      .attr("in", "coloredBlur")
                      feMerge.append("feMergeNode")
                      .attr("in", "coloredBlur")
                      //feMerge.append("feMergeNode")
                      //    .attr("in", "SourceGraphic");
                      */
            filter.append("feGaussianBlur")
                .attr("stdDeviation","3.5")
                .attr("result","coloredBlur");
            const feMerge = filter.append("feMerge");
            feMerge.append("feMergeNode")
                .attr("in","coloredBlur");
            feMerge.append("feMergeNode")
                .attr("in","SourceGraphic");

            d3.select(ref.current)
                  .append('div')
                  .attr('id', 'd3tooltip')
            const g = svg.current.append("g")
            projection.current = geoMercator().fitSize([w, h], uats)
            //const projection = geoMercator().center([0,0]).scale(1)
            const render = geoPath().projection(projection.current)

            // Data and color scale
            const generator = d3.scaleLinear()
                  .domain([1,6])
                  .range([d3.rgb("#655b6d"), d3.rgb('#2f213a')])
            const domain = [0, 1, 5, 10, 100, 50000]
            const colorScale = d3.scaleThreshold()
                  .domain(domain)
            //.interpolate(d3.interpolateHcl)
                  .range(d3.range(domain.length).map(generator))
            //.range(['#655b6d', '#2f213a']);
            const radiusScale = d3.scaleLog()
                  .domain([1, max+1])
                  .range([1, Math.min(w, h)/40])

            const renderLoc =geoPath().pointRadius(
                f => radiusScale((toploc[parseInt(f.properties.natCode, 10)] || 0)+1)
            ).projection(projection.current)


            g.selectAll("path.uat")
                .data(uats.features)
                .enter()
                .append("path")
                .attr("class", "uat")
                .attr("d", render)
                .attr("fill", d => colorScale(topuat[parseInt(d.properties.natcode, 10)] || 0))
                .on('mouseover', d => {
                    const val = topuat[parseInt(d.properties.natcode, 10)] || 0
                    d3.select('#d3tooltip')
                        .text(`${d.properties.natLevName} ${d.properties.name} (${val} firme în top)`)
                        .transition().duration(200).style('opacity', 1)
                })
                .on('mouseout', function() {
                    d3.select('#d3tooltip').style('opacity', 0)
                })
                .on('mousemove', function() {
                    d3.select('#d3tooltip')
                        .style('left', (d3.mouse(this)[0]+20) + 'px')
                        .style('top', (d3.mouse(this)[1]) + 'px')
                })
            g.selectAll("path.loc")
                .data(locs.features)
                .enter()
                .append("path")
                .attr("class", "loc")
                .attr("d", renderLoc)
                .attr('fill', '#fff')
                .style("filter", "url(#glow)")
            //.attr('fill', "#e6760e")
            //.attr('stroke', "#81756b")
                .attr('stroke-width', "5")
                .on('mouseover', d => {
                    const val = toploc[parseInt(d.properties.natCode, 10)] || 0
                    d3.select('#d3tooltip')
                        .text(`${d.properties.name}  (${val} firme în top)`)
                        .transition().duration(200).style('opacity', 1)
                })
                .on('mouseout', function() {
                    d3.select('#d3tooltip').style('opacity', 0)
                })
                .on('mousemove', function() {
                    d3.select('#d3tooltip')
                        .style('left', (d3.mouse(this)[0]+20) + 'px')
                        .style('top', (d3.mouse(this)[1]) + 'px')
                })


            const zoomed = () => {
                const {transform} = d3.event;
                //console.log(d3.event)
                g.attr("transform", transform);
                //g.attr("transform", d => `translate(${transform.apply(d)})`);
                /*projection
                  .scale(transform.k / (2 * Math.PI))
                  .translate([transform.x, transform.y])
                  path.attr("d", render)*/
            }
            svg.current.call(d3.zoom()
                     .extent([[0, 0], [w, h]])
                     .scaleExtent([0.1,10])
                     .on("zoom", zoomed))
            /*
            //const p = svg.selectAll("g")
            */
        }
    }, [uats, locs, topuat, toploc, max, w, h])

    return ref
}

const Map = ({ w, h, requestClose }) => {
    const ref = useMap(w, h)
    return  <div id="bnd3map" ref={ref}/>
}
export default Map
