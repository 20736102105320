import React from "react"
import { useQuery, Link } from "lib"

const mainMenuQuery = {
    collection: "menu",
    query: {
        label: "Meniu principal",
    },
}
export const useMainMenu = () => {
    const [mainMenu] = useQuery(mainMenuQuery, { single: true, tag: "mainMenu" })
    return mainMenu ? mainMenu.items : []
}

const renderLink = (item, i, language) => {
    const hasI18n = item._i18n
        ? Object.keys(item._i18n).filter(l => l === language).length > 0
        : false
    const data = hasI18n ? item._i18n[language] : item

    return (
        <li key={i} className={item.items && item.items.length > 0 ? "has-submenu" : ""}>
            <Link to={data.p}>{data.title}</Link>
            {item.items && (
                <ul className="menu">
                    {item.items.map((item, i) => renderLink(item, i, language))}
                </ul>
            )}
        </li>
    )
}

const MainMenu = ({ id, menu, language, onActivate }) => (
    <ul id={id} className="main-menu">
        {menu.map((item, i) => renderLink(item, i, language, onActivate))}
    </ul>
)

export default React.memo(MainMenu)
