import React from "react"
import { AuthButton, useQuery, EntityView, Region } from "lib"
import Sponsors from "./Sponsors"

const footerQuery = {
    collection: "site",
    query: {
        type: "footer",
    },
}
const footerConfig = { setEntityInfo: true, single: true }

const Footer = ({ node, language }) => {
    const [entity] = useQuery(footerQuery, footerConfig)
    const info = { language }
    if (!node) return null
    return (
        <>
            <Sponsors />
            <footer id="footer">
                <div className="footer-in">
                    <EntityView entity={entity} info={info}>
                        {info => <Region entity={entity} language={language} dynamic />}
                    </EntityView>
                </div>
                <div className="copyright">
                    Copyright © 1990-{new Date().getFullYear()} Camera de Comerț și Industrie
                    Bistrița-Năsăud
                </div>

                <AuthButton />
            </footer>
        </>
    )
}
export default React.memo(Footer)
