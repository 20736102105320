import React from "react"
import { useQuery } from "lib"
import { prepareString } from "conf/dataImport"

const queryConfig = {}
/*const prepareString = s =>
    s
        ? s
              .trim()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .replace(/[\s+]/gim, " ")
              .replace(/[^0-9a-z\s]/gim, "")
        : ""
*/
const useSearch = (year, search, filters) => {
    const query = React.useMemo(() => {
        const s = prepareString(search)

        if (s === "" && filters.length === 0) return null
        let q = { year, position: { $lte: 3 } }
        //if (s !== "") q["$text"] = { $search: `"${s}"` }
        //if (s !== "") q["$text"] = { $search: s }
        //if (s !== "") q["$text"] = { $search: s.split() }
        if (s !== "") q["ngram"] = { $regex: `^${s.replace(" ", ".*")}` }
        filters.forEach(filter => {
            Object.keys(filter.filter).forEach(key => {
                if (q[key]) {
                    if (q[key]["$in"]) q[key]["$in"].push(filter.filter[key])
                    else q[key] = { $in: [q[key], filter.filter[key]] }
                } else {
                    q[key] = filter.filter[key]
                }
            })
        })
        return {
            collection: "business",
            pipeline: [
                { $match: q },
                { $sort: { position: 1 } },
                //{ $sort: { score: { $meta: "textScore" } } },
                {
                    $project: {
                        domain: 1,
                        groupId: 1,
                        group: 1,
                        size: 1,
                        name: 1,
                        cui: 1,
                        str: 1,
                        no: 1,
                        bloc: 1,
                        sc: 1,
                        et: 1,
                        ap: 1,
                        sector: 1,
                        postal: 1,
                        loc: 1,
                        judet: 1,
                        siruta: 1,
                        position: 1,
                        web: 1,
                        path: 1,
                        _id: 1,
                        //score: { $meta: "textScore" },
                    },
                },
                {
                    $group: {
                        _id: { domain: "$domain", groupId: "$groupId", size: "$size" },
                        domain: { $first: "$domain" },
                        group: { $first: "$group" },
                        groupId: { $first: "$groupId" },
                        size: { $first: "$size" },
                        top: { $push: "$$ROOT" },
                    },
                },
                { $sort: { size: 1 } },
                {
                    $group: {
                        _id: { domain: "$_id.domain", groupId: "$_id.groupId" },
                        domain: { $first: "$domain" },
                        group: { $first: "$group" },
                        groupId: { $first: "$groupId" },
                        sizes: { $push: { size: "$size", top: "$top" } },
                    },
                },
                { $sort: { domain: 1, group: 1 } },
                {
                    $group: {
                        _id: { domain: "$_id.domain" },
                        domain: { $first: "$domain" },
                        groups: {
                            $push: { group: "$group", groupId: "$groupId", sizes: "$sizes" },
                        },
                    },
                },
                { $sort: { domain: 1 } },
            ],
        }
    }, [search, year, filters])
    const [results] = useQuery(query, queryConfig)
    //console.log(filters, query, state, results)
    return results
}
export default useSearch
