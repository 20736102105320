import React from "react"
import useTooltip from "./useTooltip"

const WithTooltip = ({ children, tag = "div", tooltipStyle = null, tooltip, ...args }) => {
    const [handlers, Tooltip] = useTooltip(tooltipStyle)
    const Tag = tag
    return (
        <Tag {...args} {...handlers}>
            {children}
            <Tooltip tooltip={tooltip} />
        </Tag>
    )
}
export default WithTooltip
