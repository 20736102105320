const trophyKinds = [
    { val: 1, label: "Trofeul Creativității" },
    { val: 2, label: "Trofeul Exportatorilor" },
]
const entityFields = {
    NotFound: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    homepage: [],
    page: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    gallery: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie", region: "gallery", renderer:"GalleryImg" },
    ],
    businessNode: [
        { name: "title", type: "text", label: "Nume", tag: "h1", region: "header" },
        { name: "cui", type: "text", label: "CUI" },
        { name: "logo", type: "img", label: "Logo" },
    ],
    business: [
        { name: "year", type: "int", label: "An" },
        { name: "cui", type: "text", label: "CUI" },
        { name: "regCom", type: "text", label: "" },
        { name: "name", type: "text", label: "Nume" },
        { name: "inc", type: "text", label: "" },
        { name: "domain", type: "text", label: "" },
        { name: "groupId", type: "text", label: "" },
        { name: "group", type: "text", label: "" },
        { name: "size", type: "text", label: "" },
        { name: "position", type: "int", label: "Pozitie" },
        { name: "loc", type: "text", label: "" },
        { name: "str", type: "text", label: "" },
        { name: "no", type: "text", label: "" },
        { name: "bloc", type: "text", label: "" },
        { name: "sc", type: "text", label: "" },
        { name: "et", type: "text", label: "" },
        { name: "ap", type: "text", label: "" },
        { name: "sector", type: "text", label: "" },
        { name: "postal", type: "text", label: "" },
        { name: "siruta", type: "text", label: "" },
        { name: "phone", type: "text", label: "" },
        { name: "fax", type: "text", label: "" },
        { name: "email", type: "text", label: "" },
        { name: "web", type: "text", label: "" },
        { name: "judet", type: "text", label: "" },
        { name: "caen", type: "text", label: "" },
        { name: "caenstr", type: "text", label: "" },
        { name: "ca", type: "text", label: "" },
        { name: "nrsal", type: "text", label: "" },
        { name: "ngram", type: "obj", label: "" },
        { name: "path", type: "text", label: "" },
    ],
    data: [
        { name: "year", type: "int", label: "An" },
        { name: "file", type: "file", label: "Fișier" },
    ],
    trophy: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "year", type: "int", label: "Anul" },
        { name: "kind", type: "select", label: "Tip", values: trophyKinds },
        { name: "top", type: "list", label: "Top", items: "business" },
    ],
    ad: [
        {
            name: "business",
            type: "ref",
            label: "Firma",
            ref: "businessNode",
            cache: "title,path",
            selectQuery: inputValue => ({
                query: {
                    bundle: "businessNode",
                    title: { $regex: `^${inputValue}.*`, $options: "im" },
                },
                projection: { title: 1, path: 1 },
                sort: { title: 1 },
                limit: 10,
            }),
        },
        { name: "file", type: "file", label: "Fișier" },
    ],
    sponsors: [
        { name: "year", type: "int", label: "Anul" },
        { name: "main", type: "list", label: "Sponsori principali", items: "sponsor" },
        { name: "regular", type: "list", label: "Sponsori", items: "sponsor" },
    ],
    bg: [
        { name: "bg", type: "img", label: "Fundal", region: "bg" },
        { name: "interval", type: "int", label: "Interval", region: "none" },
    ],
    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "list", items: "menuItem", label: "Meniuri" },
    ],
    footer: [],
}
export { entityFields }
