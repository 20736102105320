import React from "react"
import { LazyImage } from "lib"

const GalleryImgRenderer = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    //console.log(value)
    const handleMouseEnter = e => {
        let mx = 0
        let my = 0
        let lastMx = 0
        let lastMy = 0
        const handleMouseLeave = e => {
            //console.log("leave", a, timer)
            if (a) cancelAnimationFrame(a)
            if (timer) {
                clearTimeout(timer)
            } else {
                e.currentTarget.removeEventListener("mousemove", handleMouseMove)
            }
            imgInner2.style.transform = ""
            imgInner2.style.transition = "all 1s ease-in-out"
            e.currentTarget.removeEventListener("mouseleave", handleMouseLeave)
        }

        const move = () => {
            a = 0
            const k = 0.2
            const delta = 5
            let dx = mx - lastMx
            if (dx < -delta) lastMx = lastMx - delta
            else {
                if (dx > delta) lastMx = lastMx + delta
                else lastMx = mx
            }
            let dy = my - lastMy
            if (dy < -delta) lastMy = lastMy - delta
            else {
                if (dy > delta) lastMy = lastMy + delta
                else lastMy = my
            }
            const x = lastMx - rect.left
            const y = lastMy - rect.top
            const kx = -((2 * x) / rect.width - 1) * rect.width * k
            const ky = -((2 * y) / rect.height - 1) * rect.width * k
            imgInner2.style.transform = `translate3d(${kx}px, ${ky}px, 0)`
            if (mx !== lastMx && my !== lastMy) {
                a = requestAnimationFrame(move)
            }
            //console.log(mx, my)
        }
        const handleMouseMove = e => {
            timer = 0
            mx = e.clientX
            my = e.clientY
            if (a) return
            a = requestAnimationFrame(move)
            //console.log("move", x, y, rect.width, rect.height)
        }
        const imgInner2 = e.currentTarget.querySelector(".img-inner2")
        const rect = e.currentTarget.getBoundingClientRect()
        const imgInner = e.currentTarget
        lastMx = mx = e.clientX
        lastMy = my = e.clientY
        let a = requestAnimationFrame(move)

        let timer = setTimeout(() => {
            imgInner2.style.transition = "none"
            imgInner.addEventListener("mousemove", handleMouseMove)
        }, 1000)
        e.currentTarget.addEventListener("mouseleave", handleMouseLeave)
    }
    return (
        <div ref={domRef} {...props}>
            {value.map((img, i) => (
                <div key={i} className="item">
                    <div className="img" onMouseEnter={handleMouseEnter}>
                        <div className="img-inner">
                            <div className="img-inner2">
                                <LazyImage src={img} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="no">
                        <div className="line" />
                        <div className="label">{i + 1}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default React.memo(GalleryImgRenderer)
