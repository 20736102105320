import React from "react"
import { realPath, Region, useSubscription } from "lib"
import Business from "./Business"

const TrophyLayout = ({ entity, language }) => {
    useSubscription("website-loaded")
    //console.log(entity)
    const iconPath = realPath(
        entity.kind === 1
            ? "/img/layout/3-trofee-ccibn/trofeu-ccibn-creativitatii.svg"
            : "/img/layout/3-trofee-ccibn/trofeu-ccibn-exportatorilor.svg"
    )
    const title = entity.kind === 1 ? "Creativității" : "Exportatorilor"
    const className = `trophy-region kind-${entity.kind}`
    return (
        <Region region="trophy" entity={entity} language={language}>
            <div className={className}>
                <div className="trophy-title">
                    <img className="trophy-icon" src={iconPath} alt="Trofeu" />
                    <h1>
                        <span className="trophy-title-1">Trofeul </span>{" "}
                        <span className="trophy-title-2">{title}</span>
                    </h1>
                </div>

                <div className="trophy-content">
                    {entity.top.map((b, i) => (
                        <Business key={i} businessRef={b} position={i + 1} />
                    ))}
                </div>
            </div>
        </Region>
    )
}
export default TrophyLayout
