import LinkRenderer from "./LinkRenderer"
import Link from "./Link"
import Quote from "./Quote"
import Icon from "./Icon"

import Block from "./Block"
import GalleryImg from "./GalleryImg"

const fieldRenderer = {
    Block,
    GalleryImg,
    LinkRenderer,
    Link,
    Icon,
    Quote,
}
export default fieldRenderer
