import React from "react"
//import { useScreen, useSubscription } from "lib"
//import { TimelineMax, TweenMax, Elastic, Expo } from "gsap"
//import { realPath } from "lib/util"
//import requestIdleCallback from "lib/idle"
//import { publish } from "lib/hooks/useSubscription"
import { C, Link, realPath } from "lib"
import useLoading from "./useLoading"
/*
const retract = screen => {
    const t = new TimelineMax()
    t.pause()

    const w = Math.min(screen.W * 0.1, 80)

    t.add(
        TweenMax.to("#logo", 1, {
            height: (3 * w) / 2,
            width: w,
            //scale: 1,
            x: 20,
            y: 20,
            ease: Expo.easeOut,
        }),
        0
    )
    t.add(
        TweenMax.to("#cup", 1, {
            height: w / 2,
            width: w / 2,
            x: 0,
            y: 0,
            ease: Expo.easeOut,
        }),
        0
    )
    t.add(
        TweenMax.to("#top-year", 1, {
            height: w,
            width: w,
            x: 0,
            y: 0,
            padding: 10,
            ease: Expo.easeOut,
        }),
        0
    )
    t.add(TweenMax.to("#top-year .l1, #top-year .l3", 1, { height: 4, ease: Expo.easeOut }), 0)
    t.add(TweenMax.to("#top-year .l2, #top-year .l4", 1, { width: 4, ease: Expo.easeOut }), 0)

    t.add(TweenMax.to("#header", 1, { background: "#292929" }), 0.5)
    t.add(TweenMax.to("#header .title", 1, { opacity: 1 }), 1.5)
    t.add(
        TweenMax.fromTo("#header .main-menu", 1, { opacity: 1, y: -100 }, { opacity: 1, y: 0 }),
        1.5
    )

    t.play()
}

const reveal = screen => {
    const t = new TimelineMax()
    t.pause()

    //const w = screen.W * 0.1
    const w = screen.W * 0.3
    const delta = 1
    t.add(
        TweenMax.to("#logo", 2, {
            width: w,
            height: (3 * w) / 2,
            x: screen.W / 2 - w / 2,
            y: screen.H / 2 - w,
            ease: Expo.easeOut,
        }),
        delta
    )
    t.add(
        TweenMax.to("#cup", 2, {
            width: w / 2,
            height: w / 2,
            y: 5,
            ease: Expo.easeOut,
        }),
        delta
    )
    t.add(
        TweenMax.to("#top-year", 2, {
            width: w,
            height: w,
            padding: screen.W * 0.04,
            ease: Expo.easeOut,
        }),
        delta
    )
    t.add(
        TweenMax.to("#top-year .l1, #top-year .l3", 2, {
            height: screen.W / 100,
            ease: Expo.easeOut,
        }),
        delta
    )
    t.add(
        TweenMax.to("#top-year .l2, #top-year .l4", 2, {
            width: screen.W / 100,
            ease: Expo.easeOut,
        }),
        delta
    )
    t.add(TweenMax.to("#header", 1, { background: "transparent" }), 0.5)
    t.add(TweenMax.to("#header .title", 1, { opacity: 0 }), 0)
    t.add(
        TweenMax.fromTo("#header .main-menu", 1, { opacity: 1, y: 0 }, { opacity: 1, y: -100 }),
        0
    )

    t.play()
}

const revealFirst = screen => {
    const t = new TimelineMax()
    t.pause()
    const w = screen.W * 0.3
    t.set("#logo", {
        width: w,
        height: (3 * w) / 2,
        x: screen.W / 2 - w / 2,
        y: screen.H / 2 - w,
        opacity: 1,
    })
    t.set("#cup", {
        width: w / 2,
        height: w / 2,
        opacity: 1,
        y: (3 * w) / 4,
        scale: 0,
    })

    t.set("#top-year", {
        opacity: 1,
        width: w,
        height: w,
        //x: 0,
        y: w - 10,
        padding: screen.W * 0.04,
    })

    t.set("#top-year-text", { opacity: 1, scale: 0 })

    // bg
    t.add(TweenMax.fromTo("#bg-image", 4, { opacity: 0 }, { opacity: 1 }), 6)

    // cup
    t.add(TweenMax.to("#cup", 1, { scale: 2, ease: Elastic.easeOut.config(2.5, 0.7) }), 3)
    t.add(TweenMax.to("#cup", 0.5, { y: 5, scale: 1 }), 5)

    // top-year
    t.add(TweenMax.fromTo("#top-year", 0.5, { y: w - 10 }, { y: 0 }), 5)
    t.add(TweenMax.fromTo("#top-year .l1", 0.5, { x: "-30vw" }, { x: 0 }), 4.5)
    t.add(TweenMax.fromTo("#top-year .l2", 0.5, { y: "-30vw" }, { y: 0 }), 5)
    t.add(TweenMax.fromTo("#top-year .l3", 0.5, { x: "30vw" }, { x: 0 }), 5.5)
    t.add(
        TweenMax.fromTo(
            "#top-year .l4",
            0.5,
            { y: "30vw" },
            {
                y: 0,
                onComplete: () => {
                    publish("layout", "logo")
                },
            }
        ),
        6
    )

    t.add(TweenMax.to("#top-year-text", 0.5, { scale: 1, ease: Elastic.easeOut }), 6.5)
    //t.eventCallback("onComplete", retract, [screen])

    t.play()
}


const useLogo = () => {
    const retracted = React.useRef(false)
    const screen = useScreen()
    const first = React.useRef(true)
    React.useEffect(() => {
        if (first.current) {
            first.current = false
            requestIdleCallback(() => {
                revealFirst(screen)
            })
        }
    }, [screen, first])
    
    useSubscription("layout", (topic, value) => {
        switch (value) {
            case "retract":
                if (retracted.current) return
                retracted.current = true
                retract(screen)
                break
            case "logo":
                if (!retracted.current) return
                retracted.current = false
                reveal(screen)
                break
            default:
                break
        }
    })
}
*/
const Logo = () => {
    //useLogo()
    useLoading()
    /*
    const handleLogoActivate = () => {
        publish("layout", "logo")
        return true
    }*/

    return (
        <Link id="logo" to="/">
            <img id="cup" src={realPath("/img/layout/trofeu-ccibn-1.svg")} alt={C.WEBSITE_NAME} />
            <div id="top-year">
                <span className="l1 l" />
                <span className="l2 l" />
                <span className="l3 l" />
                <span className="l4 l" />
                <img
                    src={realPath("/img/layout/sigla-topul-jud-al-firmelor-bn-2019-brown-2.svg")}
                    id="top-year-text"
                    alt=""
                />
            </div>
        </Link>
    )
}
export default Logo
