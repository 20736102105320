import React from "react"
import { useQuery, LazyImage } from "lib"

const options = {}

const useData = entity => {
    const query = React.useMemo(
        () => ({
            collection: "ad",
            query: {
                "business.ref": entity._id.$oid,
            },
        }),
        [entity]
    )

    const [data] = useQuery(query, options)
    return data
}

const BusinessAds = ({ business }) => {
    const ads = useData(business)

    if (!ads || ads.length === 0) return null
    return ads.map((ad, i) => (
        <div className="business-card ad" key={i}>
            <LazyImage src={ad.file[0]} />
        </div>
    ))
}
export default BusinessAds
