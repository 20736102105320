import axios from "axios"
import React from "react"
import { realPath } from "lib"

const uatUrl = "/gis-bn/countyMn_BN.geojson"
const locUrl = "/gis-bn-loc/countyMn_BN.geojson"
const useMapData = () => {
    const [data, setData] = React.useState([null, null])
    React.useEffect(() => {
        axios.get(realPath(uatUrl)).then(result => setData(data => [result.data, data[1]]))
        axios.get(realPath(locUrl)).then(result => setData(data => [data[0], result.data]))
    }, [])
    return data
}
export default useMapData
