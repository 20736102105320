import React from "react"
import { useQuery, useSubscription, Region, Icon, Carousel } from "lib"
import { renderAddress, domains } from "../lib"
import BusinessAds from "./BusinessAds"

const BusinessCard = ({ business }) => (
    <div className="business-card">
        <div className="name">{business.name}</div>
        <div className="cui">CUI: {business.cui}</div>
        <div className="address">{renderAddress(business)}</div>
        {business.web && (
            <div className="web">
                <a href={`http://${business.web}`} target="_blank" rel="noopener noreferrer">
                    {business.web}
                </a>
            </div>
        )}
    </div>
)

const options = {}

const useData = entity => {
    const query = React.useMemo(
        () => ({
            collection: "business",
            query: {
                cui: entity.cui || "NA",
            },
            sort: { year: -1 },
        }),
        [entity]
    )

    const [data] = useQuery(query, options)
    return data
}

const BusinessPosition = ({ data }) => {
    useSubscription("website-loaded")
    return (
        <div className="business-top-year">
            <Carousel autoSize>
                {data.map((business, i) => (
                    <div key={i} className="business-top-year-item">
                        <h2>Topul Județean al Firmelor, Bistrița-Năsăud</h2>
                        <h3>{business.year}</h3>
                        <div className="position">Locul {business.position}</div>
                        <div className="domain">
                            <div className="icon">
                                <Icon
                                    name={domains[business.domain]}
                                    id={`domain-${domains[business.domain]}`}
                                />
                            </div>
                            <div>
                                <div>{business.domain}</div>
                                <div className="caen">
                                    {business.caen}. {business.caenstr}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

const BusinessLayout = ({ entity, language }) => {
    const data = useData(entity)
    if (!data || data.length === 0) return null

    let business = data[0]

    return (
        <Region region="business" entity={entity} language={language}>
            <div className="business">
                <div className="business-logo">
                    <Icon name="Trofeu" />
                </div>

                <div className="business-content">
                    <BusinessCard business={business} />
                    <BusinessPosition business={business} data={data} />
                    <BusinessAds business={entity} />
                </div>
            </div>
        </Region>
    )
}
export default BusinessLayout
