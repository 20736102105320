import React from "react"
import { prepareString } from "conf/dataImport"

const prepareString2 = s =>
    s
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()

export const renderString = (str, search) => {
    if (!search || search.trim() === "") return str
    const s = prepareString2(search)
    const sp = prepareString(search)
    const s1 = prepareString2(str)
    let i = s1.indexOf(s)
    if (i < 0) {
        i = s1.indexOf(sp)
        if (i < 0) return str
        return (
            <>
                {str.substring(0, i)}
                <span className="highlight">{str.substring(i, i + sp.length)}</span>
                {str.substring(i + sp.length)}
            </>
        )
    }
    return (
        <>
            {str.substring(0, i)}
            <span className="highlight">{str.substring(i, i + search.length)}</span>
            {str.substring(i + search.length)}
        </>
    )
}
