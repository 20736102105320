import React from "react"
import ReactDOM from "react-dom"
import { useScreen } from "lib"

const updatePosition = (x, y, size, W, H) => {
    let posX = x
    let posY = y + 30
    if (!size) return [posX, posY]
    const [w, h] = size
    if (posX + w <= W && posY + h <= H) {
        return [posX, posY]
    }
    if (x + w > W) {
        posX = W - w
        if (posX < 0) posX = 0
    }
    if (posY + h > H) {
        posY = y - h - 10
        if (posY < 0) posY = y + 30
    }
    return [posX, posY]
}

const Tooltip = ({ tooltip, tooltipSetActive, tooltipRef, children }) => {
    const size = React.useRef()
    const [active, setActive] = React.useState()

    React.useEffect(() => {
        //console.log(active, size.current, tooltipRef.current)
        if (active && !size.current && tooltipRef.current) {
            const rect = tooltipRef.current.getBoundingClientRect()
            size.current = [rect.width, rect.height]
            tooltipSetActive.current = [active, setActive, size.current]
        }
    }, [tooltipSetActive, tooltipRef, active])
    React.useEffect(() => {
        if (!tooltipSetActive.current) {
            tooltipSetActive.current = [active, setActive, size.current]
        }
        return () => {
            tooltipSetActive.current = null
        }
    }, [tooltipSetActive, active])
    if (!active) {
        size.current = null
        return null
    }
    const TooltipComp = tooltip || React.Fragment
    return ReactDOM.createPortal(
        <>
            <TooltipComp />
            {children}
        </>,
        tooltipRef.current
    )
}

const useTooltip = className => {
    const screen = useScreen()
    const [, trigger] = React.useState(0)
    const tooltipSetActive = React.useRef()
    const tooltipRef = React.useRef()
    const timeoutRef = React.useRef()
    const visibleRef = React.useRef()
    React.useEffect(() => {
        return () => {
            if (tooltipRef.current) document.body.removeChild(tooltipRef.current)
        }
    }, [])
    const hide = () => {
        if (tooltipRef.current) tooltipRef.current.style.display = "none"
        visibleRef.current = false
        if (tooltipSetActive.current) tooltipSetActive.current[1](false)
    }
    return React.useMemo(() => {
        const update = (show, x, y) => {
            if (!tooltipSetActive.current) {
                trigger(state => state + 1)
                return
            }
            if (!tooltipSetActive.current[0]) tooltipSetActive.current[1](true)
            if (!tooltipRef.current) {
                tooltipRef.current = document.createElement("div")
                tooltipRef.current.className = `tooltip ${className || ""}`
                document.body.appendChild(tooltipRef.current)
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
                timeoutRef.current = null
            }
            if (show) {
                if (!visibleRef.current) {
                    tooltipRef.current.style.display = "block"
                    visibleRef.current = true
                }

                const [posX, posY] = updatePosition(
                    x,
                    y,
                    tooltipSetActive.current[2],
                    screen.W,
                    screen.H
                )
                tooltipRef.current.style.transform = `translate(${posX}px, ${posY}px)`
                timeoutRef.current = setTimeout(hide, 1500)
            } else {
                //timeoutRef.current = setTimeout(hide, 500)
                hide()
            }
            //ref.current({show, x, y})
        }
        return [
            {
                onMouseEnter: e => update(true, e.clientX, e.clientY),
                onMouseMove: e => update(true, e.clientX, e.clientY),
                onMouseLeave: e => update(false, e.clientX, e.clientY),
            },
            ({ tooltip, children }) => (
                <Tooltip
                    tooltip={tooltip}
                    tooltipRef={tooltipRef}
                    tooltipSetActive={tooltipSetActive}
                >
                    {children}
                </Tooltip>
            ),
        ]
    }, [className, screen])
}
export default useTooltip
