import React from "react"
import { useNode, useHistory, useIntersection, useSubscription, Icon } from "lib"
import Group from "./Group"
import useSearch from "./useSearch"
import { searchInitialState } from "./useSearchReducer"
import { domains } from "../lib"
import Banner from "./Banner"

const PAGE_SIZE = 10
const SearchBanner = ({ n }) => {
    const [no] = useSubscription("no-banners")
    const ref = React.useRef()
    const refContent = React.useRef()
    useSubscription("website-loaded")
    React.useLayoutEffect(() => {
        if (n === no) {
            ref.current.style.display = "none"
            return
        }
        if (!no || n >= no) return
        const grid = document.querySelector(".domain-content")
        const style = window.getComputedStyle(grid)
        const rowGap = parseInt(style.getPropertyValue("grid-row-gap"))
        const rowHeight = parseInt(style.getPropertyValue("grid-auto-rows"))
        //const rowGap = 20
        //const rowHeight = 0
        const height = refContent.current.getBoundingClientRect().height
        const rowSpan = Math.ceil((height + rowGap) / (rowHeight + rowGap))
        //console.log(rowGap, rowHeight, height, rowSpan)
        ref.current.style.gridRowEnd = "span " + rowSpan
        //ref.current.style.opacity = 1
    })

    if (n > no) return null
    return (
        <div ref={ref} className="search-banner">
            <div ref={refContent}>
                <Banner n={n} />
            </div>
        </div>
    )
}

const SearchResults = () => {
    const [[searchState]] = useSubscription("search")
    const { year, search, filters } = searchState || searchInitialState
    const ref = React.useRef()
    const searchRef = React.useRef({ year, search })
    const history = useHistory()
    const searchParam = useNode("search")
    const page = React.useRef()
    page.current = parseInt(searchParam.page, 10) || 1
    const no = page.current * PAGE_SIZE
    //const params = useNode("search")
    //const [no, setNo] = React.useState(5)
    const filteredTop = useSearch(year, search, filters)
    const [fullTop] = useSubscription("top")
    //console.log(year, top, results)
    if (year !== searchRef.current.year || search !== searchRef.current.search) {
        if (page.current > 1) history.pushSearch(null)
    }
    /*React.useEffect(() => {
        const h = document.querySelector(".search-bar").clientHeight
        document
            .querySelectorAll(".domain-header")
            .forEach(el => (el.style.top = `calc(var(--node-padding-top) + ${h}px)`))
    }, [no])*/
    React.useMemo(() => {
        searchRef.current = { year, search }
        //setNo(5)
    }, [year, search])
    useIntersection(ref, ({ isIntersecting }) => {
        if (!isIntersecting || !top) return
        if (history.location.pathname !== "/top-firme") return
        history.pushSearch({ page: page.current + 1 })
        /*setNo(no => {
            return no + 5
        })*/
    })
    let banner = -1
    let bannerPos = Math.floor(Math.random() * 5)
    const showBanner = n => {
        if (n !== bannerPos) return null
        banner += 1
        bannerPos += Math.floor(Math.random() * 4) + 3
        return <SearchBanner n={banner} />
    }
    const top = filteredTop || fullTop
    //console.log(top)
    let n = 0
    return (
        <div className="search-results">
            {top &&
                top.map((domain, i) => {
                    if (n > no) return null
                    return (
                        <div key={i} className="domain">
                            <div className="domain-header">
                                <h2>
                                    <div className="icon">
                                        <Icon
                                            name={domains[domain.domain]}
                                            id={`domain-${domains[domain.domain]}`}
                                        />
                                    </div>{" "}
                                    <span>{domain.domain}</span>
                                </h2>
                            </div>
                            <div className="domain-content">
                                {domain.groups.map((group, j) => {
                                    if (n > no) return null
                                    n += 1
                                    return (
                                        <React.Fragment key={n}>
                                            <Group
                                                fullTop={fullTop}
                                                //domain={domain}
                                                group={group}
                                                search={search}
                                            />
                                            {showBanner(n)}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            <div ref={ref} />
        </div>
    )
}
export default SearchResults
/*
            {top &&
                top.map((domain, i) => {
                    if (n > no) return null
                    return domain.groups.map((group, j) => {
                        if (n > no) return null
                        n += 1
                        return (
                            <Group
                              key={n}
                              fullTop={fullTop}
                              domain={domain}
                              group={group}
                              search={search}
                              />
                        )
                    })
                })}
*/
