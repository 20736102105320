import React from "react"
import { Region } from "lib"

const PageLayout = ({ entity, language }) => (
    <>
        <Region entity={entity} language={language} region="header" />
        <Region entity={entity} language={language} dynamic />
    </>
)

export default React.memo(PageLayout)
/*
  <div className="region-center">
  <div className="region-in">
  <div className="region-center-content">
  <Region entity={entity} language={language} dynamic />
  </div>
  </div>
  </div>
*/
