import React from "react"
import { Region } from "lib"
import Domains from "./Domains"
import SearchBar from "./SearchBar"
import SearchResults from "./SearchResults"

const Top = ({ entity, language }) => {
    //const [location, search] = useNode("location,search")
    //const [state] = useSubscription('home')

    //console.log(location, search)
    return (
        <Region entity={entity} language={language}>
            <Domains />
            <div>
                <SearchBar />
                <SearchResults />
            </div>
        </Region>
    )
}

export default Top
