import React from "react"

import { Icon, Link, useQuery } from "lib"
import { renderAddress, domains } from "../lib"

const options = {}
const useBusiness = ref => {
    //console.log(ref)
    const qNode = React.useMemo(
        () => ({
            collection: "node",
            query: {
                _id: ref.business.ref,
            },
        }),
        [ref]
    )
    //console.log(qNode)
    const [data] = useQuery(qNode, options)
    const node = data && data.length === 1 ? data[0] : null
    const qTop = React.useMemo(
        () => ({
            collection: "business",
            query: {
                cui: node ? node.cui : "NA",
            },
            sort: { year: -1 },
            limit: 1,
        }),
        [node]
    )
    const [topData] = useQuery(qTop, options)
    const top = topData && topData.length === 1 ? topData[0] : null
    return [node, top]
}

const Business = ({ businessRef, position }) => {
    const [node, business] = useBusiness(businessRef)
    //if (!business) return null
    //console.log(node, business)

    return (
        <div className="business">
            <div className="position">
                <div className="position-inner">
                    <div className="label">Locul</div>
                    <div className="place">{position}</div>
                </div>
            </div>
            <div className="card">
                {business && (
                    <>
                        <div className="name">
                            <Link to={node.path}>{business.name}</Link>
                        </div>
                        <div className="card-content">
                            <div className="card-content-left">
                                <div className="cui">CUI: {business.cui}</div>
                                <div className="address">{renderAddress(business)}</div>
                                {business.web && (
                                    <div className="web">
                                        <a
                                            href={`http://${business.web}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {business.web}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="card-content-right">
                                <div className="domain">
                                    <div className="icon">
                                        <Icon
                                            name={domains[business.domain]}
                                            id={`domain-${domains[business.domain]}`}
                                        />
                                    </div>
                                    <div>
                                        <div>{business.domain}</div>
                                        <div className="caen">
                                            {business.caen}. {business.caenstr}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
export default React.memo(Business)
