import React from "react"
import { useScreen, requestIdleCallback, publish } from "lib"
//import { TimelineMax, TweenMax, Elastic, Expo } from "gsap"
import gsap from "gsap"

const setup = (t, screen) => {
    let w = screen.W * 0.3
    if (screen.O === "portrait") w = screen.W * 0.7
    if (screen.H < w * 2) w = screen.H * 0.47

    let vars = {
        width: w,
        height: (3 * w) / 2,
        x: screen.W / 2 - w / 2,
        y: screen.H / 2 - w,
        opacity: 1,
    }
    t.set("#logo", vars)
    vars = {
        width: w / 2,
        height: w / 2,
        opacity: 1,
        y: (3 * w) / 4,
        scale: 0,
    }
    t.set("#cup", vars)

    let hl = 12
    let padding = screen.W * 0.04
    if (screen.O === "portrait") {
        hl = 12
        padding = 24
    }
    vars = {
        opacity: 1,
        width: w,
        height: w,
        y: w - 10,
        padding,
    }
    t.set("#top-year", vars)
    t.set("#top-year .l1, #top-year .l3", { height: hl })
    t.set("#top-year .l2, #top-year .l4", { width: hl })
    t.set("#top-year-text", { opacity: 1, scale: 0 })
    return w
}
const blowup = (t, w, screen) => {
    const delta = 0
    // bg
    t.fromTo("#bg", { backgroundColor: "#fff" }, { duration: 2, backgroundColor: "#fbfaf6" }, 1)
    t.fromTo("#bg-image", { opacity: 0 }, { opacity: 1, duration: 4 }, 2)

    // cup
    t.to("#cup", { scale: 2, ease: "elastic(2.5, 0.7)", duration: 1 }, delta)
    t.to("#cup", { y: 5, scale: 1, duration: 0.5 }, delta + 2)

    // top-year
    t.fromTo("#top-year", { y: w - 10 }, { y: 0, duration: 0.5 }, delta + 2)
    t.fromTo("#top-year .l1", { x: -w }, { x: 0, duration: 0.5 }, delta + 1.5)
    t.fromTo("#top-year .l2", { y: -w }, { y: 0, duration: 0.5 }, delta + 2)
    t.fromTo("#top-year .l3", { x: w }, { x: 0, duration: 0.5 }, delta + 2.5)
    t.fromTo(
        "#top-year .l4",

        { y: w },
        {
            y: 0,
            duration: 0.5,
        },
        delta + 3
    )

    t.to("#top-year-text", { scale: 1, ease: "elastic", duration: 0.5 }, delta + 3.5)
}
const retract = (t, delta, screen) => {
    const w = Math.min(screen.W * 0.1, 80)
    if (screen.W < 1200) {
        let y = 5
        if (screen.W > 900) y = 15
        t.to(
            "#logo",
            {
                height: "auto", //(3 * w) / 2,
                width: "auto", //w,
                scale: 1,
                x: 20,
                y,
                ease: "expo",
                duration: 1,
            },
            delta
        )

        t.to(
            "#cup",
            {
                height: 38,
                width: 38,
                x: 0,
                y: 0,
                ease: "expo",
                duration: 1,
            },
            delta
        )
        t.to(
            "#top-year",
            {
                height: 38,
                width: 38,
                x: 0,
                y: 0,
                padding: 10,
                opacity: 0,
                ease: "expo",
                onComplete: () => {
                    t.set("#top-year", { display: "none" })
                },
                duration: 1,
            },
            delta
        )
    } else {
        t.set("#top-year", { display: "block" }, delta)
        t.to(
            "#logo",
            {
                height: (3 * w) / 2,
                width: w,
                //scale: 1,
                x: 20,
                y: 20,
                ease: "expo",
                duration: 1,
            },
            delta
        )
        t.to(
            "#cup",
            {
                height: w / 2,
                width: w / 2,
                x: 0,
                y: 0,
                ease: "expo",
                duration: 1,
            },
            delta
        )
        t.to(
            "#top-year",
            {
                height: w,
                width: w,
                x: 0,
                y: 0,
                opacity: 1,
                padding: 10,
                ease: "expo",
                duration: 1,
            },
            delta
        )
        t.to(
            "#top-year .l1, #top-year .l3",
            {
                height: 4,
                ease: "expo",
                duration: 1,
            },
            delta
        )
        t.to(
            "#top-year .l2, #top-year .l4",
            {
                width: 4,
                ease: "expo",
                duration: 1,
            },
            delta
        )
    }
    t.fromTo(
        "#header",
        { backgroundColor: "#fff" },
        { backgroundColor: "#2a303b", duration: 1 },
        delta - 1
    )
    t.to("#header .header-in", { opacity: 1, duration: 0.5 }, delta + 1)
    t.to("#header .title", { opacity: 1, duration: 1 }, delta + 1.5)
    t.fromTo(
        "#header .main-menu",
        { opacity: 1, y: -100 },
        { opacity: 1, y: 0, duration: 1 },
        delta + 1.5
    )
    //t.add(gsap.to("#header .search-bar", 1, { opacity: 1 }), delta + 1.5)
    //t.add(gsap.to(".top-buttons", 1, { opacity: 1 }), delta + 1.5)

    // bg
    const hImage = screen.W * 0.562398703
    //const
    t.to("#bg-image", { y: hImage / 3, duration: 6.5 }, delta - 3)
    t.to("#bg-other", { opacity: 1, duration: 1 }, delta + 1.5)
    //t.set("#footer", {opacity: 1})
    //t.set(".main-content", { display: "block", opacity: 0, y: 20 }, delta + 1.5)
    //t.add(gsap.to(".main-content", 1, { opacity: 1, y: 0 }), delta + 1.5)
    //t.set(".main-content", { transform: "none" }, delta + 2.6)
    t.set(".main-content", { display: "block", opacity: 0 }, delta + 1.5)
    t.to(
        ".main-content",
        {
            opacity: 1,
            onComplete: () => {
                document.body.dataset.website = "loaded"
                publish("website-loaded", true)
            },
            duration: 1,
        },
        delta + 1.5
    )
}

const loadLogo = screen => {
    const t = gsap.timeline()
    t.pause()

    const w = setup(t, screen)
    blowup(t, w, screen)
    const delta = 4
    retract(t, delta, screen)

    t.play()
}

const resize = screen => {
    const t = gsap.timeline()
    t.pause()

    retract(t, 0, screen)

    t.play()
}

const useLoading = () => {
    const screen = useScreen()
    const first = React.useRef(true)
    React.useEffect(() => {
        if (!first.current) {
            resize(screen)
            return
        }
        first.current = false
        requestIdleCallback(() => {
            loadLogo(screen)
        })
    }, [screen, first])
}
export default useLoading
