import React from "react"
import Logo from "./Logo"
import { C, Link, Icon, realPath, useSubscription, publish } from "lib"
import MainMenu, { useMainMenu } from "./MainMenu"
import MobileMenu from "./MobileMenu"

const TopLinks = () => {
    const handleActivate = () => {
        publish("close-menu", true)
        return true
    }
    return (
        <div className="header-top-links">
            <Link to="/top-firme" onActivate={handleActivate}>
                <Icon name="Trofeu" className="trofeu" /> Top firme
            </Link>
            <Link to="/trofeul-creativitatii" onActivate={handleActivate}>
                <Icon name="Trofeu" className="trofeu" /> Trofeul Creativității
            </Link>
            <Link to="/trofeul-exportatorilor" onActivate={handleActivate}>
                <Icon name="Trofeu" className="trofeu" /> Trofeul Exportatorilor
            </Link>
        </div>
    )
}

const Header = ({ node, language, first }) => {
    const [title] = useSubscription("title")
    const menu = useMainMenu()
    return (
        <>
            <header role="banner" id="header" key="header">
                <Logo />

                <div className="header-in">
                    <div className="title">{title}</div>
                    <TopLinks />
                    <MainMenu id="main-menu" menu={menu} language={language} />
                    <MobileMenu menu={menu}>
                        <TopLinks />
                    </MobileMenu>
                </div>
            </header>
            <div id="header-mini">
                <Link id="logo-mini" to="/">
                    <img src={realPath("/img/layout/trofeu-ccibn-1.svg")} alt={C.WEBSITE_NAME} />
                </Link>
                <div className="title">{title}</div>
                <TopLinks />
                <MobileMenu menu={menu}>
                    <TopLinks />
                </MobileMenu>
            </div>
        </>
    )
}
export default React.memo(Header)
/*            <div id="menu-mini-button">
              <Icon name="Menu"/>
              </div>
*/
