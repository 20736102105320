import React from "react"

export const renderAddress = business => {
    let line1 = []
    if (business.loc) line1.push(business.loc)
    if (business.str) line1.push(business.str)
    if (business.no) line1.push("Nr. " + business.no)
    if (business.bloc) line1.push("Bl. " + business.bloc)
    if (business.sc) line1.push("Sc. " + business.sc)
    if (business.et) line1.push("Et. " + business.et)
    if (business.ap) line1.push("Ap. " + business.ap)
    let line2 = []
    if (business.judet) {
        if (business.postal) line2.push(business.postal + " " + business.judet)
        else line2.push(business.judet)
    }
    return (
        <>
            {line1.join(", ")}
            <br />
            {line2.join(", ")}
        </>
    )
}
