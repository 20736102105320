import React from "react"
import { Region, Icon, publish, realPath, useScreen, Entity } from "lib"
import { useNode } from "lib"
/*import { publish } from "lib/hooks/useSubscription"
import { realPath } from "lib/util"
import { useScreen } from "lib"
import { Entity } from "lnx"*/

const bg = () => ({
    id: "bg-gallery",
    style: {
        backgroundImage: `url(${realPath("/img/galerie/1-lg-l-D.jpg")})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
})
const useBg = () => {
    const status = useNode("status")
    React.useEffect(() => {
        if (status === "entering") publish("push-bg", bg())
        if (status === "exiting") publish("pop-bg", bg())
    }, [status])
}
const setProp = (prop, value) => (document.body.style[prop] = value)
const removeProp = prop => (document.body.style[prop] = "")
const overflowHidden = screen => ({
    overflow: "hidden",
    paddingRight: screen.W > 1200 ? "10px" : "",
})
const setProps = props => Object.keys(props).forEach(prop => setProp(prop, props[prop]))
const removeProps = props => Object.keys(props).forEach(prop => removeProp(prop))
const hide = screen => setProps(overflowHidden(screen))
const show = screen => removeProps(overflowHidden(screen))

const PresentationToggle = ({ bodyVisible, open, dispatch }) => {
    const screen = useScreen()
    React.useEffect(() => {
        if (open) hide(screen)
        else show(screen)
        return () => show(screen)
    }, [open, screen])
    const toggle = React.useCallback(() => {
        dispatch({ type: "toggle" })
    }, [dispatch])

    return (
        <div
            className={`presentation-toggle ${bodyVisible ? "hidden" : "visible"}`}
            onClick={toggle}
        ></div>
    )
}

const initialState = {
    open: false,
    bodyVisible: true,
}
const reducer = (state, action) => {
    switch (action.type) {
        case "body-visible":
            return { ...state, bodyVisible: action.value }
        case "toggle":
            return { ...state, open: !state.open }
        default:
            return state
    }
}
const useManager = entity => {
    useBg()
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const e = React.useMemo(
        () =>
            Entity.setSettings(entity, "body", {
                onIntersect: entry => {
                    dispatch({ type: "body-visible", value: entry.isIntersecting })
                },
            }),
        [entity]
    )

    return [e, state, dispatch]
}

const Layout = ({ entity, language }) => {
    const [e, state, dispatch] = useManager(entity)
    return (
        <div className="gallery-container">
            <div className={`gallery-container-in ${state.open ? "bot-open" : "bot-closed"}`}>
                <Region entity={entity} language={language} region="header">
                    <PresentationToggle
                        bodyVisible={state.bodyVisible}
                        open={state.open}
                        dispatch={dispatch}
                    />
                </Region>
                <div className="right">
                    <Region entity={e} language={language} />
                    <Region entity={entity} language={language} region="gallery" />
                </div>
            </div>
            <div className={`gallery-bottom ${state.open ? "open" : "closed"}`}>
                <div className="skyline">
                    <Icon name="Skyline" />
                </div>
                <div className="presentation">
                    <Region entity={entity} language={language} />
                </div>
            </div>
        </div>
    )
}

export default Layout
